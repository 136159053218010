// We Are Looking for Talents
import React from 'react'
import { Link, graphql } from 'gatsby'
// import Image from 'gatsby-image'
// eslint-disable-next-line
import PropTypes from 'prop-types'

import {
  Heading,
  Button,
  Container,
  Hero,
  // Icon,
  Section,
  Box,
  // Level,
  // Tile,
  // Columns,
  Content
} from 'react-bulma-components'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import ArtPortfolioSectionBg from '../components/ArtPortfolioSectionBg'

import { Tablet, Mobile } from '../utils/presets'
import { rhythm } from '../utils/typography'

// eslint-disable-next-line
const DescriptionBlock = ({ title, description, ...props }) =>
(
  <div
    css={{
      display: 'flex'
    }}
    {...props}
  >
    <p css={{ color: '#5B59FB', fontWeight: 'bold' }}>{title}</p>
  </div>
)

class AboutPage extends React.Component {
  getImage(imageName) {
    return this.props.data[imageName].childImageSharp.fluid
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = this.props.data.allMarkdownRemark.edges
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        bodyInContainer={false}
        className="index"
      >
        <SEO title="Careers" />
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 200 }}>
              <Container className="has-text-centered">
                <Section>
                  <Heading size={1} renderAs="h1">
                    We are looking for talent
                  </Heading>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <Container className="has-text-centered">
              <Section>
                <Box>
                  <Section>
                    <Content>
                      <ul
                        className="custom-list"
                        css={{
                          marginBottom: rhythm(2),
                          marginTop: rhythm(2),
                          marginLeft: '0 !important',
                          listStyle: `none`
                        }}
                      >
                        {posts.map(post => (
                          <li
                            key={post.node.fields.slug}
                            css={{
                              display: 'flex',
                              marginBottom: rhythm(0.5),
                              [Mobile]: {
                                justifyContent: 'center',
                                marginLeft: 0
                              },
                              [Tablet]: {
                                justifyContent: 'space-between',
                                textAlign: 'left',
                                marginLeft: '5rem'
                              }
                            }}
                          >
                            <h2
                              className="custom-h2"
                              css={{
                                overflowWrap: 'break-word',
                                width: '75%'
                              }}
                            >
                              <Link to={post.node.fields.slug}>
                                {post.node.frontmatter.title}
                              </Link>
                            </h2>
                            {/*<span
                            css={{
                              // color: styles.colors.light,
                              display: `flex`,
                              [Tablet]: {
                                float: `right`,
                                marginLeft: `1rem`
                              },
                              float: 'right',
                              paddingTop: '.5rem'
                            }}
                          >
                            {post.node.frontmatter.date}
                          </span>*/}
                          </li>
                        ))}
                      </ul>
                    </Content>
                  </Section>
                </Box>
              </Section>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container>
                <Section>
                  <Box>
                    <Section
                      className="custom-section"
                      css={{
                        [Mobile]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                        },
                        [Tablet]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          flexDirection: 'inherit'
                        }
                      }}
                    >
                      <div>
                        <Heading size={2} renderAs="h2">
                          Let&apos;s talk
                        </Heading>
                        <Heading subtitle size={5} renderAs="h3">
                          Software development solutions that <br /> solve real
                          business needs.
                        </Heading>
                      </div>
                      <Button
                        color="primary"
                        onClick={this.openMailTo}
                        className="responsive-button"
                        css={{
                          display: 'flex',
                          fontWeight: 'bold',
                          [Mobile]: {
                            marginTop: '2rem'
                          },
                          [Tablet]: {
                            marginTop: 0
                          }
                        }}
                      >
                        CONTACT US
                      </Button>
                    </Section>
                  </Box>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }

  decodeSupportURL = () => {
    const encoded = 'ifmmpAubohjcmz/ufdi'
    let link = ''
    for (let i = 0; i < encoded.length; i++)
      link += String.fromCharCode(encoded.charCodeAt(i) - 1)

    return link
  }

  openMailTo = e => {
    e.preventDefault()
    const link = this.decodeSupportURL()

    window.location.href = `mailto:${link}`
  }
}

AboutPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { published: { eq: true } }
        fileAbsolutePath: { regex: "/(careers)/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
